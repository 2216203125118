<template>
  <BoardLayout
    :routeName="studioId ? 'AdminClients' : 'Clients'"
    :backButton="true"
    :mainLabel="clientDetails ? clientDetails.name : ''"
    :tabs="tabs"
  ></BoardLayout>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue'
import BoardLayout from '@/components/templates/BoardLayout.vue'
import { useRoute } from 'vue-router'
import { useStore } from '@/store'
import { ActionTypes as clientActions } from '@/store/clients/actions'
export default defineComponent({
  components: {
    BoardLayout
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const studioId = route.params.studioId
    store.dispatch(clientActions.GET_CLIENT_DETAILS, +route.params.id)
    const clientDetails = computed(() => store.state.client.clientDetails)
    const tabs = []
    if (!studioId) {
      tabs.push(
        { routerName: 'ClientProjects', routerText: 'Projects' },
        { routerName: 'ClientInformation', routerText: 'Details' },
        { routerName: 'ClientDocuments', routerText: 'Documents' }
      )
    } else {
      tabs.push({ routerName: 'ClientDetailAdmin', routerText: 'Details' })
    }
    return { tabs, clientDetails, studioId }
  }
})
</script>
<style lang="sass">
.board__section
  > *:not(.board__section-heading)
    white-space: normal
    word-wrap: break-word
</style>
